import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = [ "form" ]
  search(event) {
    clearTimeout(this.timeout);
    let val = event.target.value;
    if (val.length > 0) {
      this.timeout = setTimeout(() => {
        Rails.fire(this.formTarget, 'submit')
      }, 200)
    }
  }
}
