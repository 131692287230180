import jquery from "jquery";

(function (factory) {
    // Browser globals
    factory(jquery);
}(function ($) {
    // custom summernote translations
    $.extend(true, $.summernote.lang, {
        'en-US': { /* US English(Default Language) */
            style: {
                h2: 'Headline',
                h3: 'Sub-headline'
            }
        }
    });
}));
