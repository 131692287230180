import {Controller} from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
    static targets = ['button'];

    clipboard() {
        const element = this.buttonTarget;
        // copy soft hyphen to clipboard, blink success or alert
        navigator.clipboard.writeText('­').then(function () {
            $(element).addClass('success');
            setTimeout(function () {
                $(element).removeClass('success');
            }, 1000);
        }, function (err) {
            console.log(err);
            $(element).addClass('alert');
            setTimeout(function () {
                $(element).removeClass('alert');
            }, 1000);
        })
    }
}