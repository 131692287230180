import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ['btnMenu'];

    btnMenuClicked() {
        const $btnMenu = $(this.btnMenuTargets);
        $btnMenu.toggleClass('is_active');
        $(".menu-main").slideToggle();
    }
}
