import {Controller} from "@hotwired/stimulus"

let map;

async function initMap() {
    // The location of Uluru
    const position = { lat: 47.0490, lng: 8.2378 };
    // Request needed libraries.
    //@ts-ignore
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    map = new Map(document.getElementById("map"), {
        zoom: 11,
        center: position,
        mapId: "Wiba Sport",
    });

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "Wiba Sport",
    });
}

export default class extends Controller {
    connect() {
        super.connect();
        console.log("Connected to the map");
        initMap().then(map => {
            console.log("map shown");
        });
    }

}