import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    connect() {
        const nspace = ' &nbsp; ';
        const $page_tree = $(this.element);
        const page_url = $page_tree.data('page-url');
        const reorder = $page_tree.data('reorder');
        console.log('Initializing page tree with jqtree');

        $page_tree.tree({
            dragAndDrop: (reorder === 1),
            autoEscape: false,
            saveState: true,
            onCreateLi: function (node, $li) {
                let del_link, links, roles;
                const id = node.id;
                const url = page_url + '/' + id;
                if ('roles' in node) {
                    roles = `<span class="roles">${node.roles}</span>`;
                } else {
                    roles = '';
                }

                const edit_link = '<a href="' + url + '/edit"  title="Bearbeiten" ><i class="fa fa-edit"></i></a>';
                const view_link = '<a href="' + node.url + '" target="_blank" title="Anzeigen"><i class="fa fa-eye"></i></a>';
                if (node.deletable) {
                    del_link = nspace + '<a href="' + url + '" data-turbo-confirm="Sind Sie sicher, dass Sie die Page &laquo;' + node.title + '&raquo; löschen möchten?" data-turbo-method="delete" rel="nofollow" title="Diese Seite dauerhaft entfernen"><i class="fa fa-trash"></i></a>';
                } else {
                    del_link = '';
                }

                links = roles;
                if (reorder ===1 ) {
                    links = links + del_link + nspace + edit_link + nspace;
                }
                links = '<span class="actions right">' + links + view_link + '</span>';
                $li.find('.jqtree-title').after(links);
                return true;
            },
            onCanSelectNode: function (node) {
                // node cannot be unselected
                const sel = $page_tree.tree('isNodeSelected', node);
                return !sel;
            }
        });
        // Verschieben der Seiten in der DB nachvollziehen
        $page_tree.bind('tree.move', function (e) {
            const info = e.move_info;
            $.post(page_url + '/move', {
                moved: info.moved_node.id,
                target: info.target_node.id,
                position: info.position,
                authenticity_token: $('meta[name=csrf-token]').attr('content')
            });
        });
    }

    disconnect() {
        const $page_tree = $(this.element);
        $page_tree.tree('destroy');
        $page_tree.unbind('tree.move');
    }
}
