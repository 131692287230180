import {Controller} from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
    static targets = ['input'];

    connect() {
        console.log(this.element);
    }

    submit() {
        const element = this.inputTarget;
        const form = $(element).closest('form')[0];

        if (form.requestSubmit) {
             console.log('requestSubmit');
             form.requestSubmit();
        } else {
            console.log('uhh, no requestSubmit');
            form.submit();
        }
    }

    // fill in address when predefined address is selected
    // the select is wrapped in an address block and die inputs are marked with classes
    set_address(event) {
        const element = $(event.target);
        const address = element.closest('.address-block');
        const addresses = address.data('addresses');
        console.log(addresses);
        const selected = Number(element.find(':selected').val())
        for (const addr of addresses) {
            if (addr.id === selected) {
                address.find('.name').val(addr.name);
                address.find('.street').val(addr.street);
                address.find('.zip').val(addr.zip);
                address.find('.city').val(addr.city);
                address.find('.country_code').val(addr.country_code);
                //     contacts replace the options of the tomselect
                let contacts = address.find('select.contact')
                let toms = contacts[0].tomselect
                if (toms) {
                    toms.clear(true)
                    toms.clearOptions()
                    toms.addOptions(addr.contacts)
                    toms.refreshOptions()
                }
            }
        }
    }
}
