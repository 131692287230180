import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ['hiddenDestroy', 'hiddenLink'];

  connect() {
  }

  openReveal(_event) {
    const $hiddenDestroy = $(this.hiddenDestroyTargets);
    const $hiddenLink = $(this.hiddenLinkTargets);
    const $removeDynamicObject = $(this.element);
    const title = $removeDynamicObject.data('title');
    const content = $removeDynamicObject.data('content');
    const denyMsg = $removeDynamicObject.data('deny-msg');
    const confirmMsg = $removeDynamicObject.data('confirm-msg');
    let confirmAction = $removeDynamicObject.data('confirm-action');
    if (!confirmAction) {
      confirmAction = `<a class="button call-hidden-link close-reveal">${confirmMsg}</a>`;
    }

    const html = '<div class="reveal action-reveal">' +
        `<h3>${title}</h3>` +
        `<div class="content">${content}</div>` +
        '<div class="multi-buttons">' +
        `<button class="button close-reveal">${denyMsg}</button>` +
        '<div class="float-right">' +
        confirmAction +
        '</div>' +
        '</div>' +
        '</div>';


    $('body').append(html);
    const $reveal = $('body .reveal.action-reveal:last');
    $reveal.find('.close-reveal').on('click', function () {
      $reveal.foundation('close');
    });
    $reveal.find('.call-hidden-link').on('click', function () {
      if ($hiddenLink.length > 0) {
        $hiddenLink.find('a').click();
      }
      if ($hiddenDestroy.length > 0) {
        $hiddenDestroy.find('input').val('true');
        $removeDynamicObject.closest('fieldset').hide();
      }
    });
    const foundationReveal = new Foundation.Reveal($reveal, {});
    foundationReveal.open();
    foundationReveal.$element.on('closed.zf.reveal', function (_e) {
      foundationReveal.$element.foundation('_destroy');
      $reveal.remove();
    });
  }

}
