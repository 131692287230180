import {Controller} from "@hotwired/stimulus"

let sticky = 0;
const stickybg = 300;

export default class extends Controller {

    connect() {
        const header = this.element;
        sticky = header.offsetTop;
    }

    onWindowScroll() {
        const header = this.element;
        const bg = 'stickybg';
        if (window.scrollY > sticky) {
            if (!header.classList.contains('sticky')) {
                header.classList.add("sticky");
            }
        } else {
            if (header.classList.contains('sticky')) {
                header.classList.remove("sticky");
            }
        }
        if (window.scrollY > stickybg) {
            if (!header.classList.contains(bg)) {
                header.classList.add(bg);
            }

        } else if(header.classList.contains(bg)) {
            header.classList.remove(bg);
        }
    }
}
