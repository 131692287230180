import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ['fileUploadLabel', 'fileUploadInput', 'selectedFileLabel'];

    connect() {
        const $fileUploadInput = $(this.fileUploadInputTargets);
        const $fileUploadLabel = $(this.fileUploadLabelTargets);
        const old_id = $fileUploadInput.attr('id');
        const new_id = old_id + new Date().getTime();
        $fileUploadLabel.attr('for', new_id);
        $fileUploadInput.attr('id', new_id);
    }

    fileChanged() {
        const $fileUploadInput = $(this.fileUploadInputTargets);
        const $selectedFileLabel = $(this.selectedFileLabelTargets);
        if ($fileUploadInput.val()) {
            $selectedFileLabel.text($fileUploadInput.val().split('\\').pop());
        } else {
            $selectedFileLabel.text($selectedFileLabel.data('label'));
        }
    }
}
