import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    connect() {
        this.showVariants();
    }

    // show / hide variant parts of pages part depending on type
    showVariants() {
        const $nestedFields = $(this.element);
        $nestedFields.find('.variants').hide();
        const kind = $nestedFields.find('.page_page_parts_kind select').val();
        if (kind > '') {
            $nestedFields.find(`.${kind}`).show();
        }
    }
}
