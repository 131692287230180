import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

// Connects to data-controller="category-menu"
export default class extends Controller {

    toggle(event) {
        const menu = $('#category-menu');
        const that = $(event.target);
        const vp_width = $(window).width();
        let offs = that.offset();
        menu.toggleClass('init');
        offs.top = 112;
        if (offs.left + 250 > vp_width) {
            offs.left = vp_width - 250;
        }
        menu.offset(offs);
    }

    show(event) {
        const menu = $('#category-menu');
        const that = $(event.target);
        const vp_width = $(window).width();
        let offs = that.offset();
        menu.removeClass('init');
        offs.top = 112;
        if (offs.left + 250 > vp_width) {
            offs.left = vp_width - 250;
        }
        menu.offset(offs);
    }

    hide(event) {
        const menu = $('#category-menu');
        menu.addClass('init');
    }
}
