import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

// Connects to data-controller="toggle"
export default class extends Controller {

    toggle(event) {
        const target = $(event.target).data('target');
        $(target).slideToggle();
        $(event.target).toggleClass('active');
    }
}
