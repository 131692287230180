// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

import $ from "jquery";
import "../javascript/jquery.js" // defines global jQuery for the following Plugins


// Example: Load Rails libraries in Vite.
//
import '@hotwired/turbo-rails'
//
import '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
import "foundation-sites"
import "jqtree"
import "~/vendor/jquery-ui.min"
import 'summernote/dist/summernote-lite'
// const summernote_plugins = import.meta.glob('~/vendor/summernote/*.js');
// for (const path in summernote_plugins) {
//     summernote_plugins[path]();
// }

// own summernote plugins
import '~/vendor/summernote/summernote-ext-table'
import '~/vendor/summernote/summernote-image-title'
import '~/vendor/summernote/gallery_plugin'
import '~/vendor/summernote/summernote-table-headers'
import '~/vendor/summernote/table_zebra_plugin'
import '~/vendor/summernote/table_styles_plugin'
import '~/vendor/summernote/summernote_translations'

import '~/vendor/notify'

import "~/controllers"

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
function triggerJavascriptInit() {
    $(document).foundation();
    $('.menu-main').removeClass('hidden')
}

// is this page displayed on ios?
function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function detect_ios() {
    if(iOS()){
        $('body').addClass('js-ios')
    }
}
$(document).on('turbo:load', function () {
    triggerJavascriptInit();
    detect_ios();
});
$(document).on('turbo:render', function () {
    triggerJavascriptInit();
});
document.addEventListener("turbo:frame-missing", (event) => {
    const { detail: { response, visit } } = event;
    event.preventDefault();
    visit(response.url);
});
