import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
    // show notify and reset value when an item is added to cart
    connect() {
        let item = $(this.element);
        let msg = item.data('msg');
        let target = $(item.data('target'));

        if (target.length < 1)
            return;

        target.val(1);
        target.notify(msg, 'success');
    }
}